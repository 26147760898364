import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Logo from "./SSH.PNG";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import SSJ1 from "./SSJ1.png";
import SSJ2 from "./SSJ2.png";
import SSJ3 from "./SSJ3.png";
import "./index.css";
import Form from "react-bootstrap/Form";

function App() {
  return (
    <div className="App">
      <div className="navBar">
        <>
          <Navbar bg="dark" variant="dark">
            <Container>
              <Navbar.Brand href="#home">Saiyan Slayer High</Navbar.Brand>
              <Nav className="me-auto">
                <Nav.Link href="#get_started">Getting Started</Nav.Link>
                <Nav.Link href="#about-us">About Us</Nav.Link>
                <Nav.Link href="#enroll">Enroll NOW</Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          <br />
        </>
      </div>
      <img src={Logo} alt="Saiyan Slayer High" className="logo-container" />

      <CardGroup>
        <Card>
          <Card.Img variant="top" src={SSJ1} />
          <Card.Body>
            <Card.Title>Beginner</Card.Title>
            <Card.Text>"This is the power of a Super Saiyan"</Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Last updated 10 mins ago</small>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img variant="top" src={SSJ2} />
          <Card.Body>
            <Card.Title>Intermediate</Card.Title>
            <Card.Text>"We call this... Super Saiyan 2"</Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Last updated 120 mins ago</small>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img variant="top" src={SSJ3} />
          <Card.Body>
            <Card.Title>Expert</Card.Title>
            <Card.Text>
              "And this... is to go even <strong>further beyond!</strong>"
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">
              Last updated over 9001 mins ago
            </small>
          </Card.Footer>
        </Card>
      </CardGroup>
      <br />
      <div className="workout">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/mGeW1O3C6rI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
      <div className="workout-2">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/8y9AHa_JbNE"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
      <div className="enroll-now">
        <strong>Your Destiny Awaits!</strong>
        <br />
        Complete the form below to get started!
      </div>
      <br />
      <div className="form-container" href="#enroll">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" />
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Confirm" />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>

      {/* <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Your Destiny Awaits</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Modal body text goes here.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Learn More</Button>
        </Modal.Footer>
      </Modal.Dialog> */}
    </div>
  );
}

export default App;
